// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-development-team-tsx": () => import("./../../../src/pages/development-team.tsx" /* webpackChunkName: "component---src-pages-development-team-tsx" */),
  "component---src-pages-events-lessep-2016-tsx": () => import("./../../../src/pages/events/lessep-2016.tsx" /* webpackChunkName: "component---src-pages-events-lessep-2016-tsx" */),
  "component---src-pages-events-lessep-2017-1-tsx": () => import("./../../../src/pages/events/lessep-2017-1.tsx" /* webpackChunkName: "component---src-pages-events-lessep-2017-1-tsx" */),
  "component---src-pages-events-lessep-2017-2-tsx": () => import("./../../../src/pages/events/lessep-2017-2.tsx" /* webpackChunkName: "component---src-pages-events-lessep-2017-2-tsx" */),
  "component---src-pages-events-lessep-2017-3-tsx": () => import("./../../../src/pages/events/lessep-2017-3.tsx" /* webpackChunkName: "component---src-pages-events-lessep-2017-3-tsx" */),
  "component---src-pages-events-lessep-2018-1-tsx": () => import("./../../../src/pages/events/lessep-2018-1.tsx" /* webpackChunkName: "component---src-pages-events-lessep-2018-1-tsx" */),
  "component---src-pages-events-lessep-2019-1-tsx": () => import("./../../../src/pages/events/lessep-2019-1.tsx" /* webpackChunkName: "component---src-pages-events-lessep-2019-1-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-events-wcce-2018-tsx": () => import("./../../../src/pages/events/wcce-2018.tsx" /* webpackChunkName: "component---src-pages-events-wcce-2018-tsx" */),
  "component---src-pages-events-wistpc-2009-tsx": () => import("./../../../src/pages/events/wistpc-2009.tsx" /* webpackChunkName: "component---src-pages-events-wistpc-2009-tsx" */),
  "component---src-pages-events-wistpc-2010-tsx": () => import("./../../../src/pages/events/wistpc-2010.tsx" /* webpackChunkName: "component---src-pages-events-wistpc-2010-tsx" */),
  "component---src-pages-events-wistpc-2013-tsx": () => import("./../../../src/pages/events/wistpc-2013.tsx" /* webpackChunkName: "component---src-pages-events-wistpc-2013-tsx" */),
  "component---src-pages-events-wistpc-2014-1-tsx": () => import("./../../../src/pages/events/wistpc-2014-1.tsx" /* webpackChunkName: "component---src-pages-events-wistpc-2014-1-tsx" */),
  "component---src-pages-events-wistpc-2014-2-tsx": () => import("./../../../src/pages/events/wistpc-2014-2.tsx" /* webpackChunkName: "component---src-pages-events-wistpc-2014-2-tsx" */),
  "component---src-pages-events-wistpc-2015-tsx": () => import("./../../../src/pages/events/wistpc-2015.tsx" /* webpackChunkName: "component---src-pages-events-wistpc-2015-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-instances-tsx": () => import("./../../../src/pages/instances.tsx" /* webpackChunkName: "component---src-pages-instances-tsx" */),
  "component---src-pages-institutions-tsx": () => import("./../../../src/pages/institutions.tsx" /* webpackChunkName: "component---src-pages-institutions-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-publications-tsx": () => import("./../../../src/pages/publications.tsx" /* webpackChunkName: "component---src-pages-publications-tsx" */),
  "component---src-pages-research-team-tsx": () => import("./../../../src/pages/research-team.tsx" /* webpackChunkName: "component---src-pages-research-team-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-sponsors-tsx": () => import("./../../../src/pages/sponsors.tsx" /* webpackChunkName: "component---src-pages-sponsors-tsx" */),
  "component---src-pages-workshop-application-tsx": () => import("./../../../src/pages/workshop-application.tsx" /* webpackChunkName: "component---src-pages-workshop-application-tsx" */)
}

